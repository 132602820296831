<template>
  <div class="pres_totales" style="display:flex" v-if="pdat">
    <div class="column">
      <div class="cab">TOTAL COMPAÑIA</div>
      <v-sheet v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-input_n
            v-bind="$input"
            v-model="diferencia"
            label="% Dif"
            simbolo="%"
            readonly
          >
          </v-input_n>
          <v-input_n
            v-bind="$input"
            v-model="imp_ant"
            label="Año anterior"
            readonly
          >
          </v-input_n>

          <v-input_n
            label="Total"
            v-model="pdat.T_imp_cia"
            v-bind="$input"
            readonly
          ></v-input_n>
        </div>
      </v-sheet>
    </div>
    <div class="column">
      <div class="cab">TOTALES COMPRA</div>
      <v-sheet v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-input_n
            label="Funeraria"
            v-model="pdat.T_pnf"
            v-bind="$input"
            readonly
            :disabled="!edicion"
          ></v-input_n>
          <v-input_n
            label="pagos Directos"
            v-model="pdat.T_sup_pd"
            v-bind="$input"
            readonly
            :disabled="!edicion"
          ></v-input_n>
          <v-input_n
            label="TOTAL COMPRA"
            v-model="pdat.T_importe"
            v-bind="$input"
            readonly
            :disabled="!edicion"
          ></v-input_n>
        </div>
      </v-sheet>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pdat: { type: Object, default: null },
    imp_ant: [String, Number],
    edicion: { type: Boolean, default: false }
  },
  computed: {
    diferencia() {
      return (
        (100 * (Number(this.pdat.T_imp_cia) - Number(this.imp_ant))) /
        Number(this.imp_ant)
      );
    }
  }
};
</script>
